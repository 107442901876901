/* Auto-generated by script, do not modify */

/* ANIMATION */
:root {
  --one-ux-animation--fade-out-100: 4s linear 0s one-ux-keyframe--fade-out;
}

/* BORDER-WIDTH */
:root {
  --one-ux-border-width--100: 1px;
  --one-ux-border-width--200: 1px;
  --one-ux-border-width--300: 1px;
  --one-ux-border-width--400: 1px;
  --one-ux-border-width--500: 2px;
  --one-ux-border-width--600: 3px;
  --one-ux-border-width--700: 5px;
}

/* DURATION */
:root {
  --one-ux-duration--100: 150ms;
  --one-ux-duration--200: 150ms;
  --one-ux-duration--300: 300ms;
  --one-ux-duration--400: 300ms;
  --one-ux-duration--500: 500ms;
  --one-ux-duration--600: 500ms;
  --one-ux-duration--700: 500ms;
}

/* EASING */
:root {
  --one-ux-easing--default: cubic-bezier(0.4, 0, 0.2, 1);
  --one-ux-easing--enter: cubic-bezier(0, 0, 0.2, 1);
  --one-ux-easing--exit: cubic-bezier(0.4, 0, 1, 1);
}

/* FONT-MARGIN */
:root {
  --one-ux-font-margin--body-100: 3px 0px 5px 0px;
  --one-ux-font-margin--body-200: 0px 0px 8px 0px;
  --one-ux-font-margin--body-300: 1px 0px 11px 0px;
  --one-ux-font-margin--body-400: 3px 0px 13px 0px;
  --one-ux-font-margin--body-500: 2px 0px 26px 0px;
  --one-ux-font-margin--body-600: 2px 0px 26px 0px;
  --one-ux-font-margin--body-700: 4px 0px 26px 0px;
  --one-ux-font-margin--body-variant-100: 3px 0px 5px 0px;
  --one-ux-font-margin--body-variant-200: 0px 0px 8px 0px;
  --one-ux-font-margin--body-variant-300: 1px 0px 11px 0px;
  --one-ux-font-margin--body-variant-400: 3px 0px 13px 0px;
  --one-ux-font-margin--body-variant-500: 2px 0px 26px 0px;
  --one-ux-font-margin--body-variant-600: 2px 0px 26px 0px;
  --one-ux-font-margin--body-variant-700: 4px 0px 26px 0px;
  --one-ux-font-margin--heading-100: 3px 0px 1px 0px;
  --one-ux-font-margin--heading-200: 5px 0px 5px 0px;
  --one-ux-font-margin--heading-300: 3px 0px 5px 0px;
  --one-ux-font-margin--heading-400: 5px 0px 3px 0px;
  --one-ux-font-margin--heading-500: 9px 0px 7px 0px;
  --one-ux-font-margin--heading-600: 7px 0px 9px 0px;
  --one-ux-font-margin--heading-700: 21px 0px 23px 0px;
  --one-ux-font-margin--heading-variant-100: 3px 0px 1px 0px;
  --one-ux-font-margin--heading-variant-200: 5px 0px 5px 0px;
  --one-ux-font-margin--heading-variant-300: 3px 0px 5px 0px;
  --one-ux-font-margin--heading-variant-400: 5px 0px 3px 0px;
  --one-ux-font-margin--heading-variant-500: 9px 0px 7px 0px;
  --one-ux-font-margin--heading-variant-600: 7px 0px 9px 0px;
  --one-ux-font-margin--heading-variant-700: 21px 0px 23px 0px;
  --one-ux-font-margin--label-100: 2px 0px 1px 0px;
  --one-ux-font-margin--label-200: 2px 0px 0px 0px;
  --one-ux-font-margin--label-300: 1px 0px 0px 0px;
  --one-ux-font-margin--label-400: 3px 0px 4px 0px;
  --one-ux-font-margin--label-500: 3px 0px 4px 0px;
  --one-ux-font-margin--label-600: 5px 0px 7px 0px;
  --one-ux-font-margin--label-700: 0px 0px 7px 0px;
  --one-ux-font-margin--label-variant-100: 2px 0px 1px 0px;
  --one-ux-font-margin--label-variant-200: 2px 0px 0px 0px;
  --one-ux-font-margin--label-variant-300: 1px 0px 0px 0px;
  --one-ux-font-margin--label-variant-400: 3px 0px 4px 0px;
  --one-ux-font-margin--label-variant-500: 3px 0px 4px 0px;
  --one-ux-font-margin--label-variant-600: 5px 0px 7px 0px;
  --one-ux-font-margin--label-variant-700: 0px 0px 7px 0px;
  --one-ux-font-margin--mono-100: 2px 0px 5px 0px;
  --one-ux-font-margin--mono-200: 1px 0px 9px 0px;
  --one-ux-font-margin--mono-300: 4px 0px 9px 0px;
  --one-ux-font-margin--mono-400: 3px 0px 4px 0px;
  --one-ux-font-margin--mono-500: 2px 0px 4px 0px;
  --one-ux-font-margin--mono-600: 5px 0px 3px 0px;
  --one-ux-font-margin--mono-700: 4px 0px 3px 0px;
  --one-ux-font-margin--mono-variant-100: 2px 0px 5px 0px;
  --one-ux-font-margin--mono-variant-200: 1px 0px 9px 0px;
  --one-ux-font-margin--mono-variant-300: 4px 0px 9px 0px;
  --one-ux-font-margin--mono-variant-400: 3px 0px 4px 0px;
  --one-ux-font-margin--mono-variant-500: 2px 0px 4px 0px;
  --one-ux-font-margin--mono-variant-600: 5px 0px 3px 0px;
  --one-ux-font-margin--mono-variant-700: 4px 0px 3px 0px;
}

/* KEYFRAME */
@keyframes one-ux-keyframe--fade-out {
  from {
  }
  to {
    opacity: 0;
  }
}

/* OPACITY */
:root {
  --one-ux-opacity--unsorted-100: 0;
  --one-ux-opacity--unsorted-200: 0.125;
  --one-ux-opacity--unsorted-300: 0.25;
  --one-ux-opacity--unsorted-400: 0.5;
  --one-ux-opacity--unsorted-500: 0.75;
  --one-ux-opacity--unsorted-600: 0.875;
  --one-ux-opacity--unsorted-700: 1;
}

/* PALETTE */
:root {
  --one-ux-rgb--ai-accent-100: 240, 246, 254;
  --one-ux-palette--ai-accent-100: rgb(var(--one-ux-rgb--ai-accent-100)); /* #F0F6FE */
  --one-ux-rgb--ai-accent-200: 191, 216, 250;
  --one-ux-palette--ai-accent-200: rgb(var(--one-ux-rgb--ai-accent-200)); /* #BFD8FA */
  --one-ux-rgb--ai-accent-300: 141, 185, 246;
  --one-ux-palette--ai-accent-300: rgb(var(--one-ux-rgb--ai-accent-300)); /* #8DB9F6 */
  --one-ux-rgb--ai-accent-400: 92, 155, 242;
  --one-ux-palette--ai-accent-400: rgb(var(--one-ux-rgb--ai-accent-400)); /* #5C9BF2 */
  --one-ux-rgb--ai-accent-500: 64, 128, 216;
  --one-ux-palette--ai-accent-500: rgb(var(--one-ux-rgb--ai-accent-500)); /* #4080D8 */
  --one-ux-rgb--ai-accent-600: 38, 91, 166;
  --one-ux-palette--ai-accent-600: rgb(var(--one-ux-rgb--ai-accent-600)); /* #265BA6 */
  --one-ux-rgb--ai-accent-700: 12, 45, 92;
  --one-ux-palette--ai-accent-700: rgb(var(--one-ux-rgb--ai-accent-700)); /* #0C2D5C */
  --one-ux-rgb--ai-core-100: 248, 245, 253;
  --one-ux-palette--ai-core-100: rgb(var(--one-ux-rgb--ai-core-100)); /* #F8F5FD */
  --one-ux-rgb--ai-core-200: 212, 195, 243;
  --one-ux-palette--ai-core-200: rgb(var(--one-ux-rgb--ai-core-200)); /* #D4C3F3 */
  --one-ux-rgb--ai-core-300: 177, 146, 232;
  --one-ux-palette--ai-core-300: rgb(var(--one-ux-rgb--ai-core-300)); /* #B192E8 */
  --one-ux-rgb--ai-core-400: 141, 96, 222;
  --one-ux-palette--ai-core-400: rgb(var(--one-ux-rgb--ai-core-400)); /* #8D60DE */
  --one-ux-rgb--ai-core-500: 105, 69, 171;
  --one-ux-palette--ai-core-500: rgb(var(--one-ux-rgb--ai-core-500)); /* #6945AB */
  --one-ux-rgb--ai-core-600: 69, 42, 120;
  --one-ux-palette--ai-core-600: rgb(var(--one-ux-rgb--ai-core-600)); /* #452A78 */
  --one-ux-rgb--ai-core-700: 33, 15, 69;
  --one-ux-palette--ai-core-700: rgb(var(--one-ux-rgb--ai-core-700)); /* #210F45 */
  --one-ux-rgb--avatar-1: 170, 66, 146;
  --one-ux-palette--avatar-1: rgb(var(--one-ux-rgb--avatar-1)); /* #AA4292 */
  --one-ux-rgb--avatar-10: 23, 123, 133;
  --one-ux-palette--avatar-10: rgb(var(--one-ux-rgb--avatar-10)); /* #177B85 */
  --one-ux-rgb--avatar-11: 198, 58, 128;
  --one-ux-palette--avatar-11: rgb(var(--one-ux-rgb--avatar-11)); /* #C63A80 */
  --one-ux-rgb--avatar-12: 107, 81, 158;
  --one-ux-palette--avatar-12: rgb(var(--one-ux-rgb--avatar-12)); /* #6B519E */
  --one-ux-rgb--avatar-13: 204, 94, 94;
  --one-ux-palette--avatar-13: rgb(var(--one-ux-rgb--avatar-13)); /* #CC5E5E */
  --one-ux-rgb--avatar-14: 164, 74, 14;
  --one-ux-palette--avatar-14: rgb(var(--one-ux-rgb--avatar-14)); /* #A44A0E */
  --one-ux-rgb--avatar-15: 178, 131, 0;
  --one-ux-palette--avatar-15: rgb(var(--one-ux-rgb--avatar-15)); /* #B28300 */
  --one-ux-rgb--avatar-2: 159, 132, 213;
  --one-ux-palette--avatar-2: rgb(var(--one-ux-rgb--avatar-2)); /* #9F84D5 */
  --one-ux-rgb--avatar-3: 158, 204, 20;
  --one-ux-palette--avatar-3: rgb(var(--one-ux-rgb--avatar-3)); /* #9ECC14 */
  --one-ux-rgb--avatar-4: 99, 128, 13;
  --one-ux-palette--avatar-4: rgb(var(--one-ux-rgb--avatar-4)); /* #63800D */
  --one-ux-rgb--avatar-5: 87, 117, 140;
  --one-ux-palette--avatar-5: rgb(var(--one-ux-rgb--avatar-5)); /* #57758C */
  --one-ux-rgb--avatar-6: 31, 61, 95;
  --one-ux-palette--avatar-6: rgb(var(--one-ux-rgb--avatar-6)); /* #1F3D5F */
  --one-ux-rgb--avatar-7: 41, 133, 107;
  --one-ux-palette--avatar-7: rgb(var(--one-ux-rgb--avatar-7)); /* #29856B */
  --one-ux-rgb--avatar-8: 51, 140, 173;
  --one-ux-palette--avatar-8: rgb(var(--one-ux-rgb--avatar-8)); /* #338CAD */
  --one-ux-rgb--avatar-9: 2, 139, 145;
  --one-ux-palette--avatar-9: rgb(var(--one-ux-rgb--avatar-9)); /* #028B91 */
  --one-ux-rgb--azure-100: 241, 249, 253;
  --one-ux-palette--azure-100: rgb(var(--one-ux-rgb--azure-100)); /* #F1F9FD */
  --one-ux-rgb--azure-200: 214, 237, 250;
  --one-ux-palette--azure-200: rgb(var(--one-ux-rgb--azure-200)); /* #D6EDFA */
  --one-ux-rgb--azure-300: 166, 217, 245;
  --one-ux-palette--azure-300: rgb(var(--one-ux-rgb--azure-300)); /* #A6D9F5 */
  --one-ux-rgb--azure-400: 125, 187, 234;
  --one-ux-palette--azure-400: rgb(var(--one-ux-rgb--azure-400)); /* #7DBBEA */
  --one-ux-rgb--azure-500: 51, 140, 173;
  --one-ux-palette--azure-500: rgb(var(--one-ux-rgb--azure-500)); /* #338CAD */
  --one-ux-rgb--azure-600: 21, 64, 97;
  --one-ux-palette--azure-600: rgb(var(--one-ux-rgb--azure-600)); /* #154061 */
  --one-ux-rgb--azure-700: 15, 34, 51;
  --one-ux-palette--azure-700: rgb(var(--one-ux-rgb--azure-700)); /* #0F2233 */
  --one-ux-rgb--blue-100: 238, 241, 252;
  --one-ux-palette--blue-100: rgb(var(--one-ux-rgb--blue-100)); /* #EEF1FC */
  --one-ux-rgb--blue-200: 195, 206, 243;
  --one-ux-palette--blue-200: rgb(var(--one-ux-rgb--blue-200)); /* #C3CEF3 */
  --one-ux-rgb--blue-300: 152, 170, 235;
  --one-ux-palette--blue-300: rgb(var(--one-ux-rgb--blue-300)); /* #98AAEB */
  --one-ux-rgb--blue-400: 88, 117, 223;
  --one-ux-palette--blue-400: rgb(var(--one-ux-rgb--blue-400)); /* #5875DF */
  --one-ux-rgb--blue-500: 39, 75, 206;
  --one-ux-palette--blue-500: rgb(var(--one-ux-rgb--blue-500)); /* #274BCE */
  --one-ux-rgb--blue-600: 31, 60, 163;
  --one-ux-palette--blue-600: rgb(var(--one-ux-rgb--blue-600)); /* #1F3CA3 */
  --one-ux-rgb--blue-700: 16, 36, 106;
  --one-ux-palette--blue-700: rgb(var(--one-ux-rgb--blue-700)); /* #10246A */
  --one-ux-rgb--brand-accent-100: 253, 246, 246;
  --one-ux-palette--brand-accent-100: rgb(var(--one-ux-rgb--brand-accent-100)); /* #FDF6F6 */
  --one-ux-rgb--brand-accent-150: 253, 222, 221;
  --one-ux-palette--brand-accent-150: rgb(var(--one-ux-rgb--brand-accent-150)); /* #FDDEDD */
  --one-ux-rgb--brand-accent-200: 253, 222, 221;
  --one-ux-palette--brand-accent-200: rgb(var(--one-ux-rgb--brand-accent-200)); /* #FDDEDD */
  --one-ux-rgb--brand-accent-250: 250, 173, 171;
  --one-ux-palette--brand-accent-250: rgb(var(--one-ux-rgb--brand-accent-250)); /* #FAADAB */
  --one-ux-rgb--brand-accent-300: 250, 173, 171;
  --one-ux-palette--brand-accent-300: rgb(var(--one-ux-rgb--brand-accent-300)); /* #FAADAB */
  --one-ux-rgb--brand-accent-350: 246, 131, 118;
  --one-ux-palette--brand-accent-350: rgb(var(--one-ux-rgb--brand-accent-350)); /* #F68376 */
  --one-ux-rgb--brand-accent-400: 246, 131, 118;
  --one-ux-palette--brand-accent-400: rgb(var(--one-ux-rgb--brand-accent-400)); /* #F68376 */
  --one-ux-rgb--brand-accent-450: 204, 94, 94;
  --one-ux-palette--brand-accent-450: rgb(var(--one-ux-rgb--brand-accent-450)); /* #CC5E5E */
  --one-ux-rgb--brand-accent-500: 204, 94, 94;
  --one-ux-palette--brand-accent-500: rgb(var(--one-ux-rgb--brand-accent-500)); /* #CC5E5E */
  --one-ux-rgb--brand-accent-550: 160, 34, 34;
  --one-ux-palette--brand-accent-550: rgb(var(--one-ux-rgb--brand-accent-550)); /* #A02222 */
  --one-ux-rgb--brand-accent-600: 160, 34, 34;
  --one-ux-palette--brand-accent-600: rgb(var(--one-ux-rgb--brand-accent-600)); /* #A02222 */
  --one-ux-rgb--brand-accent-650: 100, 4, 4;
  --one-ux-palette--brand-accent-650: rgb(var(--one-ux-rgb--brand-accent-650)); /* #640404 */
  --one-ux-rgb--brand-accent-700: 100, 4, 4;
  --one-ux-palette--brand-accent-700: rgb(var(--one-ux-rgb--brand-accent-700)); /* #640404 */
  --one-ux-rgb--brand-core-100: 246, 249, 249;
  --one-ux-palette--brand-core-100: rgb(var(--one-ux-rgb--brand-core-100)); /* #F6F9F9 */
  --one-ux-rgb--brand-core-150: 234, 244, 244;
  --one-ux-palette--brand-core-150: rgb(var(--one-ux-rgb--brand-core-150)); /* #EAF4F4 */
  --one-ux-rgb--brand-core-200: 205, 240, 240;
  --one-ux-palette--brand-core-200: rgb(var(--one-ux-rgb--brand-core-200)); /* #CDF0F0 */
  --one-ux-rgb--brand-core-250: 167, 227, 227;
  --one-ux-palette--brand-core-250: rgb(var(--one-ux-rgb--brand-core-250)); /* #A7E3E3 */
  --one-ux-rgb--brand-core-300: 134, 212, 215;
  --one-ux-palette--brand-core-300: rgb(var(--one-ux-rgb--brand-core-300)); /* #86D4D7 */
  --one-ux-rgb--brand-core-350: 86, 194, 199;
  --one-ux-palette--brand-core-350: rgb(var(--one-ux-rgb--brand-core-350)); /* #56C2C7 */
  --one-ux-rgb--brand-core-400: 10, 165, 171;
  --one-ux-palette--brand-core-400: rgb(var(--one-ux-rgb--brand-core-400)); /* #0AA5AB */
  --one-ux-rgb--brand-core-450: 1, 156, 162;
  --one-ux-palette--brand-core-450: rgb(var(--one-ux-rgb--brand-core-450)); /* #019CA2 */
  --one-ux-rgb--brand-core-500: 2, 139, 145;
  --one-ux-palette--brand-core-500: rgb(var(--one-ux-rgb--brand-core-500)); /* #028B91 */
  --one-ux-rgb--brand-core-550: 4, 123, 128;
  --one-ux-palette--brand-core-550: rgb(var(--one-ux-rgb--brand-core-550)); /* #047B80 */
  --one-ux-rgb--brand-core-600: 5, 106, 111;
  --one-ux-palette--brand-core-600: rgb(var(--one-ux-rgb--brand-core-600)); /* #056A6F */
  --one-ux-rgb--brand-core-650: 7, 90, 94;
  --one-ux-palette--brand-core-650: rgb(var(--one-ux-rgb--brand-core-650)); /* #075A5E */
  --one-ux-rgb--brand-core-700: 8, 73, 77;
  --one-ux-palette--brand-core-700: rgb(var(--one-ux-rgb--brand-core-700)); /* #08494D */
  --one-ux-rgb--brand-neutral-100: 245, 246, 247;
  --one-ux-palette--brand-neutral-100: rgb(var(--one-ux-rgb--brand-neutral-100)); /* #F5F6F7 */
  --one-ux-rgb--brand-neutral-150: 232, 235, 238;
  --one-ux-palette--brand-neutral-150: rgb(var(--one-ux-rgb--brand-neutral-150)); /* #E8EBEE */
  --one-ux-rgb--brand-neutral-200: 218, 224, 229;
  --one-ux-palette--brand-neutral-200: rgb(var(--one-ux-rgb--brand-neutral-200)); /* #DAE0E5 */
  --one-ux-rgb--brand-neutral-250: 201, 210, 217;
  --one-ux-palette--brand-neutral-250: rgb(var(--one-ux-rgb--brand-neutral-250)); /* #C9D2D9 */
  --one-ux-rgb--brand-neutral-300: 186, 197, 205;
  --one-ux-palette--brand-neutral-300: rgb(var(--one-ux-rgb--brand-neutral-300)); /* #BAC5CD */
  --one-ux-rgb--brand-neutral-350: 175, 188, 197;
  --one-ux-palette--brand-neutral-350: rgb(var(--one-ux-rgb--brand-neutral-350)); /* #AFBCC5 */
  --one-ux-rgb--brand-neutral-400: 130, 151, 168;
  --one-ux-palette--brand-neutral-400: rgb(var(--one-ux-rgb--brand-neutral-400)); /* #8297A8 */
  --one-ux-rgb--brand-neutral-450: 119, 142, 161;
  --one-ux-palette--brand-neutral-450: rgb(var(--one-ux-rgb--brand-neutral-450)); /* #778EA1 */
  --one-ux-rgb--brand-neutral-500: 87, 117, 140;
  --one-ux-palette--brand-neutral-500: rgb(var(--one-ux-rgb--brand-neutral-500)); /* #57758C */
  --one-ux-rgb--brand-neutral-550: 76, 103, 123;
  --one-ux-palette--brand-neutral-550: rgb(var(--one-ux-rgb--brand-neutral-550)); /* #4C677B */
  --one-ux-rgb--brand-neutral-600: 31, 61, 95;
  --one-ux-palette--brand-neutral-600: rgb(var(--one-ux-rgb--brand-neutral-600)); /* #1F3D5F */
  --one-ux-rgb--brand-neutral-650: 14, 41, 63;
  --one-ux-palette--brand-neutral-650: rgb(var(--one-ux-rgb--brand-neutral-650)); /* #0E293F */
  --one-ux-rgb--brand-neutral-700: 16, 31, 47;
  --one-ux-palette--brand-neutral-700: rgb(var(--one-ux-rgb--brand-neutral-700)); /* #101F2F */
  --one-ux-rgb--chart-1: 54, 189, 187;
  --one-ux-palette--chart-1: rgb(var(--one-ux-rgb--chart-1)); /* #36BDBB */
  --one-ux-rgb--chart-10: 88, 185, 151;
  --one-ux-palette--chart-10: rgb(var(--one-ux-rgb--chart-10)); /* #58B997 */
  --one-ux-rgb--chart-11: 166, 217, 245;
  --one-ux-palette--chart-11: rgb(var(--one-ux-rgb--chart-11)); /* #A6D9F5 */
  --one-ux-rgb--chart-12: 230, 162, 162;
  --one-ux-palette--chart-12: rgb(var(--one-ux-rgb--chart-12)); /* #E6A2A2 */
  --one-ux-rgb--chart-13: 246, 131, 118;
  --one-ux-palette--chart-13: rgb(var(--one-ux-rgb--chart-13)); /* #F68376 */
  --one-ux-rgb--chart-14: 255, 219, 145;
  --one-ux-palette--chart-14: rgb(var(--one-ux-rgb--chart-14)); /* #FFDB91 */
  --one-ux-rgb--chart-15: 204, 94, 94;
  --one-ux-palette--chart-15: rgb(var(--one-ux-rgb--chart-15)); /* #CC5E5E */
  --one-ux-rgb--chart-16: 254, 211, 66;
  --one-ux-palette--chart-16: rgb(var(--one-ux-rgb--chart-16)); /* #FED342 */
  --one-ux-rgb--chart-17: 186, 197, 205;
  --one-ux-palette--chart-17: rgb(var(--one-ux-rgb--chart-17)); /* #BAC5CD */
  --one-ux-rgb--chart-2: 91, 121, 144;
  --one-ux-palette--chart-2: rgb(var(--one-ux-rgb--chart-2)); /* #5B7990 */
  --one-ux-rgb--chart-3: 153, 227, 222;
  --one-ux-palette--chart-3: rgb(var(--one-ux-rgb--chart-3)); /* #99E3DE */
  --one-ux-rgb--chart-4: 51, 140, 173;
  --one-ux-palette--chart-4: rgb(var(--one-ux-rgb--chart-4)); /* #338CAD */
  --one-ux-rgb--chart-5: 125, 187, 234;
  --one-ux-palette--chart-5: rgb(var(--one-ux-rgb--chart-5)); /* #7DBBEA */
  --one-ux-rgb--chart-6: 153, 227, 191;
  --one-ux-palette--chart-6: rgb(var(--one-ux-rgb--chart-6)); /* #99E3BF */
  --one-ux-rgb--chart-7: 41, 133, 107;
  --one-ux-palette--chart-7: rgb(var(--one-ux-rgb--chart-7)); /* #29856B */
  --one-ux-rgb--chart-8: 240, 176, 94;
  --one-ux-palette--chart-8: rgb(var(--one-ux-rgb--chart-8)); /* #F0B05E */
  --one-ux-rgb--chart-9: 21, 64, 97;
  --one-ux-palette--chart-9: rgb(var(--one-ux-rgb--chart-9)); /* #154061 */
  --one-ux-rgb--chartreuse-100: 245, 255, 217;
  --one-ux-palette--chartreuse-100: rgb(var(--one-ux-rgb--chartreuse-100)); /* #F5FFD9 */
  --one-ux-rgb--chartreuse-200: 236, 255, 179;
  --one-ux-palette--chartreuse-200: rgb(var(--one-ux-rgb--chartreuse-200)); /* #ECFFB3 */
  --one-ux-rgb--chartreuse-300: 223, 255, 128;
  --one-ux-palette--chartreuse-300: rgb(var(--one-ux-rgb--chartreuse-300)); /* #DFFF80 */
  --one-ux-rgb--chartreuse-400: 198, 255, 26;
  --one-ux-palette--chartreuse-400: rgb(var(--one-ux-rgb--chartreuse-400)); /* #C6FF1A */
  --one-ux-rgb--chartreuse-500: 158, 204, 20;
  --one-ux-palette--chartreuse-500: rgb(var(--one-ux-rgb--chartreuse-500)); /* #9ECC14 */
  --one-ux-rgb--chartreuse-600: 99, 128, 13;
  --one-ux-palette--chartreuse-600: rgb(var(--one-ux-rgb--chartreuse-600)); /* #63800D */
  --one-ux-rgb--chartreuse-700: 59, 77, 8;
  --one-ux-palette--chartreuse-700: rgb(var(--one-ux-rgb--chartreuse-700)); /* #3B4D08 */
  --one-ux-rgb--cyan-100: 237, 249, 249;
  --one-ux-palette--cyan-100: rgb(var(--one-ux-rgb--cyan-100)); /* #EDF9F9 */
  --one-ux-rgb--cyan-200: 195, 239, 236;
  --one-ux-palette--cyan-200: rgb(var(--one-ux-rgb--cyan-200)); /* #C3EFEC */
  --one-ux-rgb--cyan-300: 153, 227, 222;
  --one-ux-palette--cyan-300: rgb(var(--one-ux-rgb--cyan-300)); /* #99E3DE */
  --one-ux-rgb--cyan-400: 54, 189, 187;
  --one-ux-palette--cyan-400: rgb(var(--one-ux-rgb--cyan-400)); /* #36BDBB */
  --one-ux-rgb--cyan-500: 34, 160, 158;
  --one-ux-palette--cyan-500: rgb(var(--one-ux-rgb--cyan-500)); /* #22A09E */
  --one-ux-rgb--cyan-600: 23, 123, 133;
  --one-ux-palette--cyan-600: rgb(var(--one-ux-rgb--cyan-600)); /* #177B85 */
  --one-ux-rgb--cyan-700: 8, 73, 77;
  --one-ux-palette--cyan-700: rgb(var(--one-ux-rgb--cyan-700)); /* #08494D */
  --one-ux-rgb--focus-100: 241, 249, 253;
  --one-ux-palette--focus-100: rgb(var(--one-ux-rgb--focus-100)); /* #F1F9FD */
  --one-ux-rgb--focus-200: 214, 237, 250;
  --one-ux-palette--focus-200: rgb(var(--one-ux-rgb--focus-200)); /* #D6EDFA */
  --one-ux-rgb--focus-300: 166, 217, 245;
  --one-ux-palette--focus-300: rgb(var(--one-ux-rgb--focus-300)); /* #A6D9F5 */
  --one-ux-rgb--focus-400: 125, 187, 234;
  --one-ux-palette--focus-400: rgb(var(--one-ux-rgb--focus-400)); /* #7DBBEA */
  --one-ux-rgb--focus-500: 51, 140, 173;
  --one-ux-palette--focus-500: rgb(var(--one-ux-rgb--focus-500)); /* #338CAD */
  --one-ux-rgb--focus-600: 21, 64, 97;
  --one-ux-palette--focus-600: rgb(var(--one-ux-rgb--focus-600)); /* #154061 */
  --one-ux-rgb--focus-700: 15, 34, 51;
  --one-ux-palette--focus-700: rgb(var(--one-ux-rgb--focus-700)); /* #0F2233 */
  --one-ux-rgb--gray-100: 255, 255, 255;
  --one-ux-palette--gray-100: rgb(var(--one-ux-rgb--gray-100)); /* #FFFFFF */
  --one-ux-rgb--gray-150: 242, 242, 242;
  --one-ux-palette--gray-150: rgb(var(--one-ux-rgb--gray-150)); /* #F2F2F2 */
  --one-ux-rgb--gray-200: 230, 230, 230;
  --one-ux-palette--gray-200: rgb(var(--one-ux-rgb--gray-200)); /* #E6E6E6 */
  --one-ux-rgb--gray-250: 222, 222, 222;
  --one-ux-palette--gray-250: rgb(var(--one-ux-rgb--gray-250)); /* #DEDEDE */
  --one-ux-rgb--gray-300: 217, 217, 217;
  --one-ux-palette--gray-300: rgb(var(--one-ux-rgb--gray-300)); /* #D9D9D9 */
  --one-ux-rgb--gray-350: 204, 204, 204;
  --one-ux-palette--gray-350: rgb(var(--one-ux-rgb--gray-350)); /* #CCCCCC */
  --one-ux-rgb--gray-400: 189, 189, 189;
  --one-ux-palette--gray-400: rgb(var(--one-ux-rgb--gray-400)); /* #BDBDBD */
  --one-ux-rgb--gray-450: 166, 166, 166;
  --one-ux-palette--gray-450: rgb(var(--one-ux-rgb--gray-450)); /* #A6A6A6 */
  --one-ux-rgb--gray-500: 145, 145, 145;
  --one-ux-palette--gray-500: rgb(var(--one-ux-rgb--gray-500)); /* #919191 */
  --one-ux-rgb--gray-550: 110, 110, 110;
  --one-ux-palette--gray-550: rgb(var(--one-ux-rgb--gray-550)); /* #6E6E6E */
  --one-ux-rgb--gray-600: 82, 82, 82;
  --one-ux-palette--gray-600: rgb(var(--one-ux-rgb--gray-600)); /* #525252 */
  --one-ux-rgb--gray-650: 64, 64, 64;
  --one-ux-palette--gray-650: rgb(var(--one-ux-rgb--gray-650)); /* #404040 */
  --one-ux-rgb--gray-700: 42, 42, 42;
  --one-ux-palette--gray-700: rgb(var(--one-ux-rgb--gray-700)); /* #2A2A2A */
  --one-ux-rgb--green-100: 222, 255, 228;
  --one-ux-palette--green-100: rgb(var(--one-ux-rgb--green-100)); /* #DEFFE4 */
  --one-ux-rgb--green-200: 185, 255, 201;
  --one-ux-palette--green-200: rgb(var(--one-ux-rgb--green-200)); /* #B9FFC9 */
  --one-ux-rgb--green-300: 110, 242, 141;
  --one-ux-palette--green-300: rgb(var(--one-ux-rgb--green-300)); /* #6EF28D */
  --one-ux-rgb--green-400: 77, 214, 99;
  --one-ux-palette--green-400: rgb(var(--one-ux-rgb--green-400)); /* #4DD663 */
  --one-ux-rgb--green-500: 62, 186, 43;
  --one-ux-palette--green-500: rgb(var(--one-ux-rgb--green-500)); /* #3EBA2B */
  --one-ux-rgb--green-600: 16, 118, 1;
  --one-ux-palette--green-600: rgb(var(--one-ux-rgb--green-600)); /* #107601 */
  --one-ux-rgb--green-700: 8, 62, 0;
  --one-ux-palette--green-700: rgb(var(--one-ux-rgb--green-700)); /* #083E00 */
  --one-ux-rgb--keyboard-focus-100: 254, 248, 235;
  --one-ux-palette--keyboard-focus-100: rgb(var(--one-ux-rgb--keyboard-focus-100)); /* #FEF8EB */
  --one-ux-rgb--keyboard-focus-200: 255, 237, 200;
  --one-ux-palette--keyboard-focus-200: rgb(var(--one-ux-rgb--keyboard-focus-200)); /* #FFEDC8 */
  --one-ux-rgb--keyboard-focus-300: 255, 219, 145;
  --one-ux-palette--keyboard-focus-300: rgb(var(--one-ux-rgb--keyboard-focus-300)); /* #FFDB91 */
  --one-ux-rgb--keyboard-focus-400: 240, 176, 94;
  --one-ux-palette--keyboard-focus-400: rgb(var(--one-ux-rgb--keyboard-focus-400)); /* #F0B05E */
  --one-ux-rgb--keyboard-focus-500: 215, 154, 0;
  --one-ux-palette--keyboard-focus-500: rgb(var(--one-ux-rgb--keyboard-focus-500)); /* #D79A00 */
  --one-ux-rgb--keyboard-focus-600: 210, 125, 20;
  --one-ux-palette--keyboard-focus-600: rgb(var(--one-ux-rgb--keyboard-focus-600)); /* #D27D14 */
  --one-ux-rgb--keyboard-focus-700: 16, 31, 47;
  --one-ux-palette--keyboard-focus-700: rgb(var(--one-ux-rgb--keyboard-focus-700)); /* #101F2F */
  --one-ux-rgb--magenta-100: 250, 235, 248;
  --one-ux-palette--magenta-100: rgb(var(--one-ux-rgb--magenta-100)); /* #FAEBF8 */
  --one-ux-rgb--magenta-200: 237, 202, 233;
  --one-ux-palette--magenta-200: rgb(var(--one-ux-rgb--magenta-200)); /* #EDCAE9 */
  --one-ux-rgb--magenta-300: 230, 163, 223;
  --one-ux-palette--magenta-300: rgb(var(--one-ux-rgb--magenta-300)); /* #E6A3DF */
  --one-ux-rgb--magenta-400: 197, 111, 188;
  --one-ux-palette--magenta-400: rgb(var(--one-ux-rgb--magenta-400)); /* #C56FBC */
  --one-ux-rgb--magenta-500: 170, 66, 146;
  --one-ux-palette--magenta-500: rgb(var(--one-ux-rgb--magenta-500)); /* #AA4292 */
  --one-ux-rgb--magenta-600: 109, 23, 90;
  --one-ux-palette--magenta-600: rgb(var(--one-ux-rgb--magenta-600)); /* #6D175A */
  --one-ux-rgb--magenta-700: 60, 12, 50;
  --one-ux-palette--magenta-700: rgb(var(--one-ux-rgb--magenta-700)); /* #3C0C32 */
  --one-ux-rgb--mint-100: 235, 254, 240;
  --one-ux-palette--mint-100: rgb(var(--one-ux-rgb--mint-100)); /* #EBFEF0 */
  --one-ux-rgb--mint-200: 195, 239, 217;
  --one-ux-palette--mint-200: rgb(var(--one-ux-rgb--mint-200)); /* #C3EFD9 */
  --one-ux-rgb--mint-300: 153, 227, 191;
  --one-ux-palette--mint-300: rgb(var(--one-ux-rgb--mint-300)); /* #99E3BF */
  --one-ux-rgb--mint-400: 88, 185, 151;
  --one-ux-palette--mint-400: rgb(var(--one-ux-rgb--mint-400)); /* #58B997 */
  --one-ux-rgb--mint-500: 41, 133, 107;
  --one-ux-palette--mint-500: rgb(var(--one-ux-rgb--mint-500)); /* #29856B */
  --one-ux-rgb--mint-600: 0, 89, 64;
  --one-ux-palette--mint-600: rgb(var(--one-ux-rgb--mint-600)); /* #005940 */
  --one-ux-rgb--mint-700: 13, 51, 34;
  --one-ux-palette--mint-700: rgb(var(--one-ux-rgb--mint-700)); /* #0D3322 */
  --one-ux-rgb--orange-100: 255, 235, 191;
  --one-ux-palette--orange-100: rgb(var(--one-ux-rgb--orange-100)); /* #FFEBBF */
  --one-ux-rgb--orange-200: 255, 213, 122;
  --one-ux-palette--orange-200: rgb(var(--one-ux-rgb--orange-200)); /* #FFD57A */
  --one-ux-rgb--orange-300: 252, 186, 45;
  --one-ux-palette--orange-300: rgb(var(--one-ux-rgb--orange-300)); /* #FCBA2D */
  --one-ux-rgb--orange-400: 251, 161, 18;
  --one-ux-palette--orange-400: rgb(var(--one-ux-rgb--orange-400)); /* #FBA112 */
  --one-ux-rgb--orange-500: 237, 133, 24;
  --one-ux-palette--orange-500: rgb(var(--one-ux-rgb--orange-500)); /* #ED8518 */
  --one-ux-rgb--orange-600: 164, 74, 14;
  --one-ux-palette--orange-600: rgb(var(--one-ux-rgb--orange-600)); /* #A44A0E */
  --one-ux-rgb--orange-700: 123, 48, 5;
  --one-ux-palette--orange-700: rgb(var(--one-ux-rgb--orange-700)); /* #7B3005 */
  --one-ux-rgb--purpose-action-attention: 255, 221, 0;
  --one-ux-palette--purpose-action-attention: rgb(var(--one-ux-rgb--purpose-action-attention)); /* #FFDD00 */
  --one-ux-rgb--purpose-action-confirm: 88, 185, 151;
  --one-ux-palette--purpose-action-confirm: rgb(var(--one-ux-rgb--purpose-action-confirm)); /* #58B997 */
  --one-ux-rgb--purpose-action-focus: 125, 187, 234;
  --one-ux-palette--purpose-action-focus: rgb(var(--one-ux-rgb--purpose-action-focus)); /* #7DBBEA */
  --one-ux-rgb--purpose-action-help: 197, 111, 188;
  --one-ux-palette--purpose-action-help: rgb(var(--one-ux-rgb--purpose-action-help)); /* #C56FBC */
  --one-ux-rgb--purpose-action-primary: 10, 165, 171;
  --one-ux-palette--purpose-action-primary: rgb(var(--one-ux-rgb--purpose-action-primary)); /* #0AA5AB */
  --one-ux-rgb--purpose-action-remove: 245, 67, 47;
  --one-ux-palette--purpose-action-remove: rgb(var(--one-ux-rgb--purpose-action-remove)); /* #F5432F */
  --one-ux-rgb--purpose-action-secondary: 218, 224, 229;
  --one-ux-palette--purpose-action-secondary: rgb(var(--one-ux-rgb--purpose-action-secondary)); /* #DAE0E5 */
  --one-ux-rgb--purpose-action-tertiary: 186, 197, 205;
  --one-ux-palette--purpose-action-tertiary: rgb(var(--one-ux-rgb--purpose-action-tertiary)); /* #BAC5CD */
  --one-ux-rgb--purpose-node-activity-completed: 88, 185, 151;
  --one-ux-palette--purpose-node-activity-completed: rgb(
    var(--one-ux-rgb--purpose-node-activity-completed)
  ); /* #58B997 */
  --one-ux-rgb--purpose-node-activity-delayed: 245, 67, 47;
  --one-ux-palette--purpose-node-activity-delayed: rgb(var(--one-ux-rgb--purpose-node-activity-delayed)); /* #F5432F */
  --one-ux-rgb--purpose-node-activity-milddelay: 246, 131, 118;
  --one-ux-palette--purpose-node-activity-milddelay: rgb(
    var(--one-ux-rgb--purpose-node-activity-milddelay)
  ); /* #F68376 */
  --one-ux-rgb--purpose-node-activity-notstarted: 130, 151, 168;
  --one-ux-palette--purpose-node-activity-notstarted: rgb(
    var(--one-ux-rgb--purpose-node-activity-notstarted)
  ); /* #8297A8 */
  --one-ux-rgb--purpose-node-activity-ongoing: 88, 117, 223;
  --one-ux-palette--purpose-node-activity-ongoing: rgb(var(--one-ux-rgb--purpose-node-activity-ongoing)); /* #5875DF */
  --one-ux-rgb--purpose-node-activity-paused: 251, 161, 18;
  --one-ux-palette--purpose-node-activity-paused: rgb(var(--one-ux-rgb--purpose-node-activity-paused)); /* #FBA112 */
  --one-ux-rgb--purpose-node-activity-soondelayed: 255, 221, 0;
  --one-ux-palette--purpose-node-activity-soondelayed: rgb(
    var(--one-ux-rgb--purpose-node-activity-soondelayed)
  ); /* #FFDD00 */
  --one-ux-rgb--purpose-node-kpi-column-100: 62, 186, 43;
  --one-ux-palette--purpose-node-kpi-column-100: rgb(var(--one-ux-rgb--purpose-node-kpi-column-100)); /* #3EBA2B */
  --one-ux-rgb--purpose-node-kpi-column-150: 77, 214, 99;
  --one-ux-palette--purpose-node-kpi-column-150: rgb(var(--one-ux-rgb--purpose-node-kpi-column-150)); /* #4DD663 */
  --one-ux-rgb--purpose-node-kpi-column-200: 110, 242, 141;
  --one-ux-palette--purpose-node-kpi-column-200: rgb(var(--one-ux-rgb--purpose-node-kpi-column-200)); /* #6EF28D */
  --one-ux-rgb--purpose-node-kpi-column-250: 255, 238, 89;
  --one-ux-palette--purpose-node-kpi-column-250: rgb(var(--one-ux-rgb--purpose-node-kpi-column-250)); /* #FFEE59 */
  --one-ux-rgb--purpose-node-kpi-column-300: 255, 221, 0;
  --one-ux-palette--purpose-node-kpi-column-300: rgb(var(--one-ux-rgb--purpose-node-kpi-column-300)); /* #FFDD00 */
  --one-ux-rgb--purpose-node-kpi-column-350: 255, 201, 26;
  --one-ux-palette--purpose-node-kpi-column-350: rgb(var(--one-ux-rgb--purpose-node-kpi-column-350)); /* #FFC91A */
  --one-ux-rgb--purpose-node-kpi-column-400: 252, 186, 45;
  --one-ux-palette--purpose-node-kpi-column-400: rgb(var(--one-ux-rgb--purpose-node-kpi-column-400)); /* #FCBA2D */
  --one-ux-rgb--purpose-node-kpi-column-450: 251, 161, 18;
  --one-ux-palette--purpose-node-kpi-column-450: rgb(var(--one-ux-rgb--purpose-node-kpi-column-450)); /* #FBA112 */
  --one-ux-rgb--purpose-node-kpi-column-500: 237, 133, 24;
  --one-ux-palette--purpose-node-kpi-column-500: rgb(var(--one-ux-rgb--purpose-node-kpi-column-500)); /* #ED8518 */
  --one-ux-rgb--purpose-node-kpi-column-550: 245, 67, 47;
  --one-ux-palette--purpose-node-kpi-column-550: rgb(var(--one-ux-rgb--purpose-node-kpi-column-550)); /* #F5432F */
  --one-ux-rgb--purpose-node-kpi-column-600: 219, 37, 37;
  --one-ux-palette--purpose-node-kpi-column-600: rgb(var(--one-ux-rgb--purpose-node-kpi-column-600)); /* #DB2525 */
  --one-ux-rgb--purpose-node-kpi-column-650: 149, 9, 9;
  --one-ux-palette--purpose-node-kpi-column-650: rgb(var(--one-ux-rgb--purpose-node-kpi-column-650)); /* #950909 */
  --one-ux-rgb--purpose-node-kpi-column-700: 149, 9, 9;
  --one-ux-palette--purpose-node-kpi-column-700: rgb(var(--one-ux-rgb--purpose-node-kpi-column-700)); /* #950909 */
  --one-ux-rgb--purpose-node-kpi-column-noimpact: 218, 224, 229;
  --one-ux-palette--purpose-node-kpi-column-noimpact: rgb(
    var(--one-ux-rgb--purpose-node-kpi-column-noimpact)
  ); /* #DAE0E5 */
  --one-ux-rgb--purpose-node-kpi-delayed: 245, 67, 47;
  --one-ux-palette--purpose-node-kpi-delayed: rgb(var(--one-ux-rgb--purpose-node-kpi-delayed)); /* #F5432F */
  --one-ux-rgb--purpose-node-kpi-fulfilled: 88, 185, 151;
  --one-ux-palette--purpose-node-kpi-fulfilled: rgb(var(--one-ux-rgb--purpose-node-kpi-fulfilled)); /* #58B997 */
  --one-ux-rgb--purpose-node-kpi-milddelay: 246, 131, 118;
  --one-ux-palette--purpose-node-kpi-milddelay: rgb(var(--one-ux-rgb--purpose-node-kpi-milddelay)); /* #F68376 */
  --one-ux-rgb--purpose-node-kpi-notstarted: 130, 151, 168;
  --one-ux-palette--purpose-node-kpi-notstarted: rgb(var(--one-ux-rgb--purpose-node-kpi-notstarted)); /* #8297A8 */
  --one-ux-rgb--purpose-node-kpi-partlyfulfilled: 255, 221, 0;
  --one-ux-palette--purpose-node-kpi-partlyfulfilled: rgb(
    var(--one-ux-rgb--purpose-node-kpi-partlyfulfilled)
  ); /* #FFDD00 */
  --one-ux-rgb--purpose-node-kpi-paused: 251, 161, 18;
  --one-ux-palette--purpose-node-kpi-paused: rgb(var(--one-ux-rgb--purpose-node-kpi-paused)); /* #FBA112 */
  --one-ux-rgb--purpose-node-text-delayed: 245, 67, 47;
  --one-ux-palette--purpose-node-text-delayed: rgb(var(--one-ux-rgb--purpose-node-text-delayed)); /* #F5432F */
  --one-ux-rgb--purpose-node-text-fulfilled: 88, 185, 151;
  --one-ux-palette--purpose-node-text-fulfilled: rgb(var(--one-ux-rgb--purpose-node-text-fulfilled)); /* #58B997 */
  --one-ux-rgb--purpose-node-text-milddelay: 246, 131, 118;
  --one-ux-palette--purpose-node-text-milddelay: rgb(var(--one-ux-rgb--purpose-node-text-milddelay)); /* #F68376 */
  --one-ux-rgb--purpose-node-text-notstarted: 130, 151, 168;
  --one-ux-palette--purpose-node-text-notstarted: rgb(var(--one-ux-rgb--purpose-node-text-notstarted)); /* #8297A8 */
  --one-ux-rgb--purpose-node-text-partlyfulfilled: 255, 221, 0;
  --one-ux-palette--purpose-node-text-partlyfulfilled: rgb(
    var(--one-ux-rgb--purpose-node-text-partlyfulfilled)
  ); /* #FFDD00 */
  --one-ux-rgb--purpose-node-text-paused: 251, 161, 18;
  --one-ux-palette--purpose-node-text-paused: rgb(var(--one-ux-rgb--purpose-node-text-paused)); /* #FBA112 */
  --one-ux-rgb--purpose-notification-attention: 255, 221, 0;
  --one-ux-palette--purpose-notification-attention: rgb(
    var(--one-ux-rgb--purpose-notification-attention)
  ); /* #FFDD00 */
  --one-ux-rgb--purpose-notification-error: 245, 67, 47;
  --one-ux-palette--purpose-notification-error: rgb(var(--one-ux-rgb--purpose-notification-error)); /* #F5432F */
  --one-ux-rgb--purpose-notification-info: 197, 111, 188;
  --one-ux-palette--purpose-notification-info: rgb(var(--one-ux-rgb--purpose-notification-info)); /* #C56FBC */
  --one-ux-rgb--purpose-notification-success: 88, 185, 151;
  --one-ux-palette--purpose-notification-success: rgb(var(--one-ux-rgb--purpose-notification-success)); /* #58B997 */
  --one-ux-rgb--purpose-risks-100: 62, 186, 43;
  --one-ux-palette--purpose-risks-100: rgb(var(--one-ux-rgb--purpose-risks-100)); /* #3EBA2B */
  --one-ux-rgb--purpose-risks-150: 77, 214, 99;
  --one-ux-palette--purpose-risks-150: rgb(var(--one-ux-rgb--purpose-risks-150)); /* #4DD663 */
  --one-ux-rgb--purpose-risks-200: 110, 242, 141;
  --one-ux-palette--purpose-risks-200: rgb(var(--one-ux-rgb--purpose-risks-200)); /* #6EF28D */
  --one-ux-rgb--purpose-risks-250: 255, 238, 89;
  --one-ux-palette--purpose-risks-250: rgb(var(--one-ux-rgb--purpose-risks-250)); /* #FFEE59 */
  --one-ux-rgb--purpose-risks-300: 255, 221, 0;
  --one-ux-palette--purpose-risks-300: rgb(var(--one-ux-rgb--purpose-risks-300)); /* #FFDD00 */
  --one-ux-rgb--purpose-risks-350: 255, 201, 26;
  --one-ux-palette--purpose-risks-350: rgb(var(--one-ux-rgb--purpose-risks-350)); /* #FFC91A */
  --one-ux-rgb--purpose-risks-400: 252, 186, 45;
  --one-ux-palette--purpose-risks-400: rgb(var(--one-ux-rgb--purpose-risks-400)); /* #FCBA2D */
  --one-ux-rgb--purpose-risks-450: 251, 161, 18;
  --one-ux-palette--purpose-risks-450: rgb(var(--one-ux-rgb--purpose-risks-450)); /* #FBA112 */
  --one-ux-rgb--purpose-risks-500: 237, 133, 24;
  --one-ux-palette--purpose-risks-500: rgb(var(--one-ux-rgb--purpose-risks-500)); /* #ED8518 */
  --one-ux-rgb--purpose-risks-550: 245, 67, 47;
  --one-ux-palette--purpose-risks-550: rgb(var(--one-ux-rgb--purpose-risks-550)); /* #F5432F */
  --one-ux-rgb--purpose-risks-600: 219, 37, 37;
  --one-ux-palette--purpose-risks-600: rgb(var(--one-ux-rgb--purpose-risks-600)); /* #DB2525 */
  --one-ux-rgb--purpose-risks-650: 149, 9, 9;
  --one-ux-palette--purpose-risks-650: rgb(var(--one-ux-rgb--purpose-risks-650)); /* #950909 */
  --one-ux-rgb--purpose-risks-700: 149, 9, 9;
  --one-ux-palette--purpose-risks-700: rgb(var(--one-ux-rgb--purpose-risks-700)); /* #950909 */
  --one-ux-rgb--purpose-risks-noimpact: 218, 224, 229;
  --one-ux-palette--purpose-risks-noimpact: rgb(var(--one-ux-rgb--purpose-risks-noimpact)); /* #DAE0E5 */
  --one-ux-rgb--purpose-status-completed: 88, 185, 151;
  --one-ux-palette--purpose-status-completed: rgb(var(--one-ux-rgb--purpose-status-completed)); /* #58B997 */
  --one-ux-rgb--purpose-status-delayed: 246, 131, 118;
  --one-ux-palette--purpose-status-delayed: rgb(var(--one-ux-rgb--purpose-status-delayed)); /* #F68376 */
  --one-ux-rgb--purpose-status-inprogress: 88, 117, 223;
  --one-ux-palette--purpose-status-inprogress: rgb(var(--one-ux-rgb--purpose-status-inprogress)); /* #5875DF */
  --one-ux-rgb--purpose-status-new: 255, 87, 171;
  --one-ux-palette--purpose-status-new: rgb(var(--one-ux-rgb--purpose-status-new)); /* #FF57AB */
  --one-ux-rgb--purpose-status-notstarted: 130, 151, 168;
  --one-ux-palette--purpose-status-notstarted: rgb(var(--one-ux-rgb--purpose-status-notstarted)); /* #8297A8 */
  --one-ux-rgb--purpose-status-paused: 251, 161, 18;
  --one-ux-palette--purpose-status-paused: rgb(var(--one-ux-rgb--purpose-status-paused)); /* #FBA112 */
  --one-ux-rgb--purpose-teamorg-help: 197, 111, 188;
  --one-ux-palette--purpose-teamorg-help: rgb(var(--one-ux-rgb--purpose-teamorg-help)); /* #C56FBC */
  --one-ux-rgb--purpose-teamorg-me: 10, 165, 171;
  --one-ux-palette--purpose-teamorg-me: rgb(var(--one-ux-rgb--purpose-teamorg-me)); /* #0AA5AB */
  --one-ux-rgb--purpose-teamorg-others: 186, 197, 205;
  --one-ux-palette--purpose-teamorg-others: rgb(var(--one-ux-rgb--purpose-teamorg-others)); /* #BAC5CD */
  --one-ux-rgb--purpose-teamorg-teamorg: 204, 94, 94;
  --one-ux-palette--purpose-teamorg-teamorg: rgb(var(--one-ux-rgb--purpose-teamorg-teamorg)); /* #CC5E5E */
  --one-ux-rgb--red-100: 253, 222, 221;
  --one-ux-palette--red-100: rgb(var(--one-ux-rgb--red-100)); /* #FDDEDD */
  --one-ux-rgb--red-200: 250, 173, 171;
  --one-ux-palette--red-200: rgb(var(--one-ux-rgb--red-200)); /* #FAADAB */
  --one-ux-rgb--red-300: 246, 131, 118;
  --one-ux-palette--red-300: rgb(var(--one-ux-rgb--red-300)); /* #F68376 */
  --one-ux-rgb--red-400: 245, 67, 47;
  --one-ux-palette--red-400: rgb(var(--one-ux-rgb--red-400)); /* #F5432F */
  --one-ux-rgb--red-500: 219, 37, 37;
  --one-ux-palette--red-500: rgb(var(--one-ux-rgb--red-500)); /* #DB2525 */
  --one-ux-rgb--red-600: 149, 9, 9;
  --one-ux-palette--red-600: rgb(var(--one-ux-rgb--red-600)); /* #950909 */
  --one-ux-rgb--red-700: 100, 4, 4;
  --one-ux-palette--red-700: rgb(var(--one-ux-rgb--red-700)); /* #640404 */
  --one-ux-rgb--rose-100: 255, 242, 249;
  --one-ux-palette--rose-100: rgb(var(--one-ux-rgb--rose-100)); /* #FFF2F9 */
  --one-ux-rgb--rose-200: 255, 190, 223;
  --one-ux-palette--rose-200: rgb(var(--one-ux-rgb--rose-200)); /* #FFBEDF */
  --one-ux-rgb--rose-300: 255, 139, 197;
  --one-ux-palette--rose-300: rgb(var(--one-ux-rgb--rose-300)); /* #FF8BC5 */
  --one-ux-rgb--rose-400: 255, 87, 171;
  --one-ux-palette--rose-400: rgb(var(--one-ux-rgb--rose-400)); /* #FF57AB */
  --one-ux-rgb--rose-500: 198, 58, 128;
  --one-ux-palette--rose-500: rgb(var(--one-ux-rgb--rose-500)); /* #C63A80 */
  --one-ux-rgb--rose-600: 141, 29, 85;
  --one-ux-palette--rose-600: rgb(var(--one-ux-rgb--rose-600)); /* #8D1D55 */
  --one-ux-rgb--rose-700: 84, 0, 42;
  --one-ux-palette--rose-700: rgb(var(--one-ux-rgb--rose-700)); /* #54002A */
  --one-ux-rgb--violet-100: 240, 236, 248;
  --one-ux-palette--violet-100: rgb(var(--one-ux-rgb--violet-100)); /* #F0ECF8 */
  --one-ux-rgb--violet-200: 216, 206, 238;
  --one-ux-palette--violet-200: rgb(var(--one-ux-rgb--violet-200)); /* #D8CEEE */
  --one-ux-rgb--violet-300: 187, 168, 225;
  --one-ux-palette--violet-300: rgb(var(--one-ux-rgb--violet-300)); /* #BBA8E1 */
  --one-ux-rgb--violet-400: 159, 132, 213;
  --one-ux-palette--violet-400: rgb(var(--one-ux-rgb--violet-400)); /* #9F84D5 */
  --one-ux-rgb--violet-500: 107, 81, 158;
  --one-ux-palette--violet-500: rgb(var(--one-ux-rgb--violet-500)); /* #6B519E */
  --one-ux-rgb--violet-600: 73, 52, 113;
  --one-ux-palette--violet-600: rgb(var(--one-ux-rgb--violet-600)); /* #493471 */
  --one-ux-rgb--violet-700: 33, 15, 69;
  --one-ux-palette--violet-700: rgb(var(--one-ux-rgb--violet-700)); /* #210F45 */
  --one-ux-rgb--yellow-100: 255, 252, 229;
  --one-ux-palette--yellow-100: rgb(var(--one-ux-rgb--yellow-100)); /* #FFFCE5 */
  --one-ux-rgb--yellow-200: 255, 249, 195;
  --one-ux-palette--yellow-200: rgb(var(--one-ux-rgb--yellow-200)); /* #FFF9C3 */
  --one-ux-rgb--yellow-300: 255, 238, 89;
  --one-ux-palette--yellow-300: rgb(var(--one-ux-rgb--yellow-300)); /* #FFEE59 */
  --one-ux-rgb--yellow-400: 255, 221, 0;
  --one-ux-palette--yellow-400: rgb(var(--one-ux-rgb--yellow-400)); /* #FFDD00 */
  --one-ux-rgb--yellow-500: 255, 201, 26;
  --one-ux-palette--yellow-500: rgb(var(--one-ux-rgb--yellow-500)); /* #FFC91A */
  --one-ux-rgb--yellow-600: 178, 131, 0;
  --one-ux-palette--yellow-600: rgb(var(--one-ux-rgb--yellow-600)); /* #B28300 */
  --one-ux-rgb--yellow-700: 122, 75, 0;
  --one-ux-palette--yellow-700: rgb(var(--one-ux-rgb--yellow-700)); /* #7A4B00 */
}

/* RADIUS */
:root {
  --one-ux-radius--100: 1px;
  --one-ux-radius--200: 2px;
  --one-ux-radius--300: 4px;
  --one-ux-radius--400: 8px;
  --one-ux-radius--500: 12px;
  --one-ux-radius--600: 16px;
  --one-ux-radius--700: 24px;
  --one-ux-radius--max: 999px;
}

/* SHADOW */
:root {
  --one-ux-shadow--drop-100: 0px 1px 3px 0px rgba(31, 61, 95, 0.2), 0px 0.2px 1.2px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--drop-200: 0px 2px 4.5px 0px rgba(31, 61, 95, 0.2), 0px 0.4px 2.4px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--drop-300: 0px 3.5px 6.8px 0px rgba(31, 61, 95, 0.2), 0px 0.8px 3.6px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--drop-400: 0px 4.5px 12px 0px rgba(31, 61, 95, 0.2), 0px 1.6px 6px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--drop-500: 0px 8px 28.8px 0px rgba(31, 61, 95, 0.22), 0px 2.4px 7.4px 0px rgba(31, 61, 95, 0.18);
  --one-ux-shadow--drop-600: 0px 16px 59.6px 0px rgba(31, 61, 95, 0.18), 0px 4.8px 14.8px 0px rgba(31, 61, 95, 0.22);
  --one-ux-shadow--drop-700: 0px 20px 66px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--uniform-100: 0px 0px 3px 0px rgba(31, 61, 95, 0.2), 0px 0px 1.2px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--uniform-200: 0px 0px 4.5px 0px rgba(31, 61, 95, 0.2), 0px 0px 2.4px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--uniform-300: 0px 0px 6.8px 0px rgba(31, 61, 95, 0.2), 0px 0px 3.6px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--uniform-400: 0px 0px 12px 0px rgba(31, 61, 95, 0.2), 0px 0px 6px 0px rgba(31, 61, 95, 0.16);
  --one-ux-shadow--uniform-500: 0px 0px 28.8px 0px rgba(31, 61, 95, 0.22), 0px 0px 7.4px 0px rgba(31, 61, 95, 0.18);
  --one-ux-shadow--uniform-600: 0px 0px 59.6px 0px rgba(31, 61, 95, 0.18), 0px 0px 14.8px 0px rgba(31, 61, 95, 0.22);
  --one-ux-shadow--uniform-700: 0px 0px 66px 0px rgba(31, 61, 95, 0.16);
}

/* SIZE */
:root {
  --one-ux-size--icon-100: 16px;
  --one-ux-size--icon-200: 18px;
  --one-ux-size--icon-300: 20px;
  --one-ux-size--icon-400: 23px;
  --one-ux-size--icon-500: 25px;
  --one-ux-size--icon-600: 29px;
  --one-ux-size--icon-700: 36px;
}

/* SPACING */
:root {
  --one-ux-spacing--100: 2px;
  --one-ux-spacing--200: 4px;
  --one-ux-spacing--300: 8px;
  --one-ux-spacing--400: 12px;
  --one-ux-spacing--500: 16px;
  --one-ux-spacing--600: 24px;
  --one-ux-spacing--700: 32px;
  --one-ux-spacing--large: 16px;
  --one-ux-spacing--larger: 24px;
  --one-ux-spacing--largest: 32px;
  --one-ux-spacing--none: 0px;
  --one-ux-spacing--normal: 8px;
  --one-ux-spacing--small: 4px;
  --one-ux-spacing--smaller: 2px;
  --one-ux-spacing--smallest: 1px;
}

/* TRANSITION */
:root {
  --one-ux-transition--test-all-100: 1s;
}

/* TYPOGRAPHY */
:root {
  --one-ux-line-height--body-100: calc(1.4 * 10px);
  --one-ux-font--body-100: 400 10px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-100: 0.5px;
  --one-ux-line-height--body-200: calc(1.4 * 12px);
  --one-ux-font--body-200: 400 12px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-200: 0.25px;
  --one-ux-line-height--body-300: calc(1.4 * 14px);
  --one-ux-font--body-300: 400 14px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-300: 0.25px;
  --one-ux-line-height--body-400: calc(1.4 * 16px);
  --one-ux-font--body-400: 400 16px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-400: 0px;
  --one-ux-line-height--body-500: calc(1.4 * 18px);
  --one-ux-font--body-500: 400 18px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-500: 0px;
  --one-ux-line-height--body-600: calc(1.4 * 22px);
  --one-ux-font--body-600: 400 22px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-600: -0.25px;
  --one-ux-line-height--body-700: calc(1.4 * 28px);
  --one-ux-font--body-700: 400 28px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-700: -0.25px;
  --one-ux-line-height--body-variant-100: calc(1.4 * 10px);
  --one-ux-font--body-variant-100: 700 10px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-100: 0.5px;
  --one-ux-line-height--body-variant-200: calc(1.4 * 12px);
  --one-ux-font--body-variant-200: 700 12px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-200: 0.25px;
  --one-ux-line-height--body-variant-300: calc(1.4 * 14px);
  --one-ux-font--body-variant-300: 700 14px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-300: 0.25px;
  --one-ux-line-height--body-variant-400: calc(1.4 * 16px);
  --one-ux-font--body-variant-400: 700 16px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-400: 0px;
  --one-ux-line-height--body-variant-500: calc(1.4 * 18px);
  --one-ux-font--body-variant-500: 700 18px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-500: 0px;
  --one-ux-line-height--body-variant-600: calc(1.4 * 22px);
  --one-ux-font--body-variant-600: 700 22px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-600: -0.25px;
  --one-ux-line-height--body-variant-700: calc(1.4 * 28px);
  --one-ux-font--body-variant-700: 700 28px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--body-variant-700: -0.25px;
  --one-ux-line-height--heading-100: 16px;
  --one-ux-font--heading-100: 700 12px / 16px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-100: 0.5px;
  --one-ux-line-height--heading-200: 18px;
  --one-ux-font--heading-200: 700 14px / 18px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-200: 0.25px;
  --one-ux-line-height--heading-300: 22px;
  --one-ux-font--heading-300: 700 16px / 22px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-300: 0px;
  --one-ux-line-height--heading-400: 24px;
  --one-ux-font--heading-400: 700 18px / 24px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-400: 0px;
  --one-ux-line-height--heading-500: 28px;
  --one-ux-font--heading-500: 700 22px / 28px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-500: 0px;
  --one-ux-line-height--heading-600: 36px;
  --one-ux-font--heading-600: 700 28px / 36px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-600: -0.25px;
  --one-ux-line-height--heading-700: 64px;
  --one-ux-font--heading-700: 700 56px / 64px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-700: -1.5px;
  --one-ux-line-height--heading-variant-100: 16px;
  --one-ux-font--heading-variant-100: 400 12px / 16px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-100: 0.5px;
  --one-ux-line-height--heading-variant-200: 18px;
  --one-ux-font--heading-variant-200: 400 14px / 18px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-200: 0.25px;
  --one-ux-line-height--heading-variant-300: 22px;
  --one-ux-font--heading-variant-300: 400 16px / 22px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-300: 0px;
  --one-ux-line-height--heading-variant-400: 24px;
  --one-ux-font--heading-variant-400: 400 18px / 24px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-400: 0px;
  --one-ux-line-height--heading-variant-500: 28px;
  --one-ux-font--heading-variant-500: 400 22px / 28px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-500: 0px;
  --one-ux-line-height--heading-variant-600: 36px;
  --one-ux-font--heading-variant-600: 400 28px / 36px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-600: -0.25px;
  --one-ux-line-height--heading-variant-700: 64px;
  --one-ux-font--heading-variant-700: 400 56px / 64px 'Plus Jakarta Sans', Arial, sans-serif;
  --one-ux-letter-spacing--heading-variant-700: -1.5px;
  --one-ux-line-height--label-100: calc(1.4 * 9px);
  --one-ux-font--label-100: 400 9px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-100: 0.5px;
  --one-ux-line-height--label-200: calc(1.4 * 10px);
  --one-ux-font--label-200: 400 10px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-200: 0.5px;
  --one-ux-line-height--label-300: calc(1.4 * 11px);
  --one-ux-font--label-300: 400 11px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-300: 0.25px;
  --one-ux-line-height--label-400: calc(1.4 * 12px);
  --one-ux-font--label-400: 400 12px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-400: 0.25px;
  --one-ux-line-height--label-500: calc(1.4 * 13px);
  --one-ux-font--label-500: 400 13px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-500: 0px;
  --one-ux-line-height--label-600: calc(1.4 * 14px);
  --one-ux-font--label-600: 400 14px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-600: 0px;
  --one-ux-line-height--label-700: calc(1.4 * 15px);
  --one-ux-font--label-700: 400 15px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-700: 0px;
  --one-ux-line-height--label-variant-100: calc(1.4 * 9px);
  --one-ux-font--label-variant-100: 700 9px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-100: 0.5px;
  --one-ux-line-height--label-variant-200: calc(1.4 * 10px);
  --one-ux-font--label-variant-200: 700 10px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-200: 0.5px;
  --one-ux-line-height--label-variant-300: calc(1.4 * 11px);
  --one-ux-font--label-variant-300: 700 11px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-300: 0.25px;
  --one-ux-line-height--label-variant-400: calc(1.4 * 12px);
  --one-ux-font--label-variant-400: 700 12px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-400: 0.25px;
  --one-ux-line-height--label-variant-500: calc(1.4 * 13px);
  --one-ux-font--label-variant-500: 700 13px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-500: 0px;
  --one-ux-line-height--label-variant-600: calc(1.4 * 14px);
  --one-ux-font--label-variant-600: 700 14px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-600: 0px;
  --one-ux-line-height--label-variant-700: calc(1.4 * 15px);
  --one-ux-font--label-variant-700: 700 15px / 140% Roboto, Arial, sans-serif;
  --one-ux-letter-spacing--label-variant-700: 0px;
  --one-ux-line-height--mono-100: calc(1.4 * 9px);
  --one-ux-font--mono-100: 400 9px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-100: 0.5px;
  --one-ux-line-height--mono-200: calc(1.4 * 10px);
  --one-ux-font--mono-200: 400 10px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-200: 0.5px;
  --one-ux-line-height--mono-300: calc(1.4 * 11px);
  --one-ux-font--mono-300: 400 11px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-300: 0.25px;
  --one-ux-line-height--mono-400: calc(1.4 * 12px);
  --one-ux-font--mono-400: 400 12px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-400: 0.25px;
  --one-ux-line-height--mono-500: calc(1.4 * 13px);
  --one-ux-font--mono-500: 400 13px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-500: 0px;
  --one-ux-line-height--mono-600: calc(1.4 * 14px);
  --one-ux-font--mono-600: 400 14px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-600: 0px;
  --one-ux-line-height--mono-700: calc(1.4 * 15px);
  --one-ux-font--mono-700: 400 15px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-700: 0px;
  --one-ux-line-height--mono-variant-100: calc(1.4 * 9px);
  --one-ux-font--mono-variant-100: 700 9px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-100: 0.5px;
  --one-ux-line-height--mono-variant-200: calc(1.4 * 10px);
  --one-ux-font--mono-variant-200: 700 10px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-200: 0.5px;
  --one-ux-line-height--mono-variant-300: calc(1.4 * 11px);
  --one-ux-font--mono-variant-300: 700 11px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-300: 0.25px;
  --one-ux-line-height--mono-variant-400: calc(1.4 * 12px);
  --one-ux-font--mono-variant-400: 700 12px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-400: 0.25px;
  --one-ux-line-height--mono-variant-500: calc(1.4 * 13px);
  --one-ux-font--mono-variant-500: 700 13px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-500: 0px;
  --one-ux-line-height--mono-variant-600: calc(1.4 * 14px);
  --one-ux-font--mono-variant-600: 700 14px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-600: 0px;
  --one-ux-line-height--mono-variant-700: calc(1.4 * 15px);
  --one-ux-font--mono-variant-700: 700 15px / 140% 'Roboto Mono', Arial, sans-serif;
  --one-ux-letter-spacing--mono-variant-700: 0px;
}

/* Z */
:root {
  --one-ux-z--unsorted-100: -100;
  --one-ux-z--unsorted-200: -10;
  --one-ux-z--unsorted-300: -1;
  --one-ux-z--unsorted-400: 0;
  --one-ux-z--unsorted-500: 1;
  --one-ux-z--unsorted-600: 10;
  --one-ux-z--unsorted-700: 100;
}

/* Auto-generated by script, do not modify */

.one-ux-typography--body-100 {
  font: var(--one-ux-font--body-100);
  letter-spacing: var(--one-ux-letter-spacing--body-100);
  margin: var(--one-ux-font-margin--body-100);
}
.one-ux-typography--body-200 {
  font: var(--one-ux-font--body-200);
  letter-spacing: var(--one-ux-letter-spacing--body-200);
  margin: var(--one-ux-font-margin--body-200);
}
.one-ux-typography--body-300 {
  font: var(--one-ux-font--body-300);
  letter-spacing: var(--one-ux-letter-spacing--body-300);
  margin: var(--one-ux-font-margin--body-300);
}
.one-ux-typography--body-400 {
  font: var(--one-ux-font--body-400);
  letter-spacing: var(--one-ux-letter-spacing--body-400);
  margin: var(--one-ux-font-margin--body-400);
}
.one-ux-typography--body-500 {
  font: var(--one-ux-font--body-500);
  letter-spacing: var(--one-ux-letter-spacing--body-500);
  margin: var(--one-ux-font-margin--body-500);
}
.one-ux-typography--body-600 {
  font: var(--one-ux-font--body-600);
  letter-spacing: var(--one-ux-letter-spacing--body-600);
  margin: var(--one-ux-font-margin--body-600);
}
.one-ux-typography--body-700 {
  font: var(--one-ux-font--body-700);
  letter-spacing: var(--one-ux-letter-spacing--body-700);
  margin: var(--one-ux-font-margin--body-700);
}
.one-ux-typography--body-variant-100 {
  font: var(--one-ux-font--body-variant-100);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-100);
  margin: var(--one-ux-font-margin--body-variant-100);
}
.one-ux-typography--body-variant-200 {
  font: var(--one-ux-font--body-variant-200);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-200);
  margin: var(--one-ux-font-margin--body-variant-200);
}
.one-ux-typography--body-variant-300 {
  font: var(--one-ux-font--body-variant-300);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
  margin: var(--one-ux-font-margin--body-variant-300);
}
.one-ux-typography--body-variant-400 {
  font: var(--one-ux-font--body-variant-400);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-400);
  margin: var(--one-ux-font-margin--body-variant-400);
}
.one-ux-typography--body-variant-500 {
  font: var(--one-ux-font--body-variant-500);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-500);
  margin: var(--one-ux-font-margin--body-variant-500);
}
.one-ux-typography--body-variant-600 {
  font: var(--one-ux-font--body-variant-600);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-600);
  margin: var(--one-ux-font-margin--body-variant-600);
}
.one-ux-typography--body-variant-700 {
  font: var(--one-ux-font--body-variant-700);
  letter-spacing: var(--one-ux-letter-spacing--body-variant-700);
  margin: var(--one-ux-font-margin--body-variant-700);
}
.one-ux-typography--heading-100 {
  font: var(--one-ux-font--heading-100);
  letter-spacing: var(--one-ux-letter-spacing--heading-100);
  margin: var(--one-ux-font-margin--heading-100);
}
.one-ux-typography--heading-200 {
  font: var(--one-ux-font--heading-200);
  letter-spacing: var(--one-ux-letter-spacing--heading-200);
  margin: var(--one-ux-font-margin--heading-200);
}
.one-ux-typography--heading-300 {
  font: var(--one-ux-font--heading-300);
  letter-spacing: var(--one-ux-letter-spacing--heading-300);
  margin: var(--one-ux-font-margin--heading-300);
}
.one-ux-typography--heading-400 {
  font: var(--one-ux-font--heading-400);
  letter-spacing: var(--one-ux-letter-spacing--heading-400);
  margin: var(--one-ux-font-margin--heading-400);
}
.one-ux-typography--heading-500 {
  font: var(--one-ux-font--heading-500);
  letter-spacing: var(--one-ux-letter-spacing--heading-500);
  margin: var(--one-ux-font-margin--heading-500);
}
.one-ux-typography--heading-600 {
  font: var(--one-ux-font--heading-600);
  letter-spacing: var(--one-ux-letter-spacing--heading-600);
  margin: var(--one-ux-font-margin--heading-600);
}
.one-ux-typography--heading-700 {
  font: var(--one-ux-font--heading-700);
  letter-spacing: var(--one-ux-letter-spacing--heading-700);
  margin: var(--one-ux-font-margin--heading-700);
}
.one-ux-typography--heading-variant-100 {
  font: var(--one-ux-font--heading-variant-100);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-100);
  margin: var(--one-ux-font-margin--heading-variant-100);
}
.one-ux-typography--heading-variant-200 {
  font: var(--one-ux-font--heading-variant-200);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-200);
  margin: var(--one-ux-font-margin--heading-variant-200);
}
.one-ux-typography--heading-variant-300 {
  font: var(--one-ux-font--heading-variant-300);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-300);
  margin: var(--one-ux-font-margin--heading-variant-300);
}
.one-ux-typography--heading-variant-400 {
  font: var(--one-ux-font--heading-variant-400);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-400);
  margin: var(--one-ux-font-margin--heading-variant-400);
}
.one-ux-typography--heading-variant-500 {
  font: var(--one-ux-font--heading-variant-500);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-500);
  margin: var(--one-ux-font-margin--heading-variant-500);
}
.one-ux-typography--heading-variant-600 {
  font: var(--one-ux-font--heading-variant-600);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-600);
  margin: var(--one-ux-font-margin--heading-variant-600);
}
.one-ux-typography--heading-variant-700 {
  font: var(--one-ux-font--heading-variant-700);
  letter-spacing: var(--one-ux-letter-spacing--heading-variant-700);
  margin: var(--one-ux-font-margin--heading-variant-700);
}
.one-ux-typography--label-100 {
  font: var(--one-ux-font--label-100);
  letter-spacing: var(--one-ux-letter-spacing--label-100);
  margin: var(--one-ux-font-margin--label-100);
}
.one-ux-typography--label-200 {
  font: var(--one-ux-font--label-200);
  letter-spacing: var(--one-ux-letter-spacing--label-200);
  margin: var(--one-ux-font-margin--label-200);
}
.one-ux-typography--label-300 {
  font: var(--one-ux-font--label-300);
  letter-spacing: var(--one-ux-letter-spacing--label-300);
  margin: var(--one-ux-font-margin--label-300);
}
.one-ux-typography--label-400 {
  font: var(--one-ux-font--label-400);
  letter-spacing: var(--one-ux-letter-spacing--label-400);
  margin: var(--one-ux-font-margin--label-400);
}
.one-ux-typography--label-500 {
  font: var(--one-ux-font--label-500);
  letter-spacing: var(--one-ux-letter-spacing--label-500);
  margin: var(--one-ux-font-margin--label-500);
}
.one-ux-typography--label-600 {
  font: var(--one-ux-font--label-600);
  letter-spacing: var(--one-ux-letter-spacing--label-600);
  margin: var(--one-ux-font-margin--label-600);
}
.one-ux-typography--label-700 {
  font: var(--one-ux-font--label-700);
  letter-spacing: var(--one-ux-letter-spacing--label-700);
  margin: var(--one-ux-font-margin--label-700);
}
.one-ux-typography--label-variant-100 {
  font: var(--one-ux-font--label-variant-100);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-100);
  margin: var(--one-ux-font-margin--label-variant-100);
}
.one-ux-typography--label-variant-200 {
  font: var(--one-ux-font--label-variant-200);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-200);
  margin: var(--one-ux-font-margin--label-variant-200);
}
.one-ux-typography--label-variant-300 {
  font: var(--one-ux-font--label-variant-300);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-300);
  margin: var(--one-ux-font-margin--label-variant-300);
}
.one-ux-typography--label-variant-400 {
  font: var(--one-ux-font--label-variant-400);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-400);
  margin: var(--one-ux-font-margin--label-variant-400);
}
.one-ux-typography--label-variant-500 {
  font: var(--one-ux-font--label-variant-500);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-500);
  margin: var(--one-ux-font-margin--label-variant-500);
}
.one-ux-typography--label-variant-600 {
  font: var(--one-ux-font--label-variant-600);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-600);
  margin: var(--one-ux-font-margin--label-variant-600);
}
.one-ux-typography--label-variant-700 {
  font: var(--one-ux-font--label-variant-700);
  letter-spacing: var(--one-ux-letter-spacing--label-variant-700);
  margin: var(--one-ux-font-margin--label-variant-700);
}
.one-ux-typography--mono-100 {
  font: var(--one-ux-font--mono-100);
  letter-spacing: var(--one-ux-letter-spacing--mono-100);
  margin: var(--one-ux-font-margin--mono-100);
}
.one-ux-typography--mono-200 {
  font: var(--one-ux-font--mono-200);
  letter-spacing: var(--one-ux-letter-spacing--mono-200);
  margin: var(--one-ux-font-margin--mono-200);
}
.one-ux-typography--mono-300 {
  font: var(--one-ux-font--mono-300);
  letter-spacing: var(--one-ux-letter-spacing--mono-300);
  margin: var(--one-ux-font-margin--mono-300);
}
.one-ux-typography--mono-400 {
  font: var(--one-ux-font--mono-400);
  letter-spacing: var(--one-ux-letter-spacing--mono-400);
  margin: var(--one-ux-font-margin--mono-400);
}
.one-ux-typography--mono-500 {
  font: var(--one-ux-font--mono-500);
  letter-spacing: var(--one-ux-letter-spacing--mono-500);
  margin: var(--one-ux-font-margin--mono-500);
}
.one-ux-typography--mono-600 {
  font: var(--one-ux-font--mono-600);
  letter-spacing: var(--one-ux-letter-spacing--mono-600);
  margin: var(--one-ux-font-margin--mono-600);
}
.one-ux-typography--mono-700 {
  font: var(--one-ux-font--mono-700);
  letter-spacing: var(--one-ux-letter-spacing--mono-700);
  margin: var(--one-ux-font-margin--mono-700);
}
.one-ux-typography--mono-variant-100 {
  font: var(--one-ux-font--mono-variant-100);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-100);
  margin: var(--one-ux-font-margin--mono-variant-100);
}
.one-ux-typography--mono-variant-200 {
  font: var(--one-ux-font--mono-variant-200);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-200);
  margin: var(--one-ux-font-margin--mono-variant-200);
}
.one-ux-typography--mono-variant-300 {
  font: var(--one-ux-font--mono-variant-300);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-300);
  margin: var(--one-ux-font-margin--mono-variant-300);
}
.one-ux-typography--mono-variant-400 {
  font: var(--one-ux-font--mono-variant-400);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-400);
  margin: var(--one-ux-font-margin--mono-variant-400);
}
.one-ux-typography--mono-variant-500 {
  font: var(--one-ux-font--mono-variant-500);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-500);
  margin: var(--one-ux-font-margin--mono-variant-500);
}
.one-ux-typography--mono-variant-600 {
  font: var(--one-ux-font--mono-variant-600);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-600);
  margin: var(--one-ux-font-margin--mono-variant-600);
}
.one-ux-typography--mono-variant-700 {
  font: var(--one-ux-font--mono-variant-700);
  letter-spacing: var(--one-ux-letter-spacing--mono-variant-700);
  margin: var(--one-ux-font-margin--mono-variant-700);
}

:is(
    one-ux-busy,
    one-ux-tabpanel-next,
    one-ux-date-picker,
    one-ux-tabs-preview,
    one-ux-tab,
    one-ux-color-picker,
    one-ux-avatar,
    one-ux-badge,
    one-ux-pill,
    one-ux-label,
    one-ux-dropdown,
    one-ux-adapter,
    one-ux-interactive-content,
    one-ux-editable-text,
    one-ux-dialog,
    one-ux-distribution,
    one-ux-bar-chart,
    one-ux-indication,
    one-ux-plot-chart,
    one-ux-progress,
    one-ux-button,
    one-ux-checkbox,
    one-ux-checkbox-group,
    one-ux-collapsible-section,
    one-ux-context-provider,
    one-ux-field,
    one-ux-field-set,
    one-ux-icon,
    one-ux-input,
    one-ux-link,
    one-ux-list,
    one-ux-menu,
    one-ux-message,
    one-ux-popout,
    one-ux-radio,
    one-ux-radio-group,
    one-ux-scroll,
    one-ux-search,
    one-ux-switch,
    one-ux-tabs,
    one-ux-text,
    one-ux-textarea,
    one-ux-tooltip,
    one-ux-tree,
    one-ux-widget-button
  ):not(:defined) {
  display: none !important;
}

.one-ux-accessibility--screen-reader,
.one-ux-accessibility--hidden-focus-group:not(:focus-within) {
  position: fixed;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.one-ux-body:where(body) {
  background: var(--one-ux-palette--brand-neutral-100);
  color: var(--one-ux-palette--brand-neutral-600);
  font: var(--one-ux-font--body-300);
  letter-spacing: var(--one-ux-letter-spacing--body-300);
}

.one-ux-reset {
  &,
  &:before,
  &:after,
  &::placeholder {
    all: revert;
  }

  /* Set all CSS values that revert to "inherit" to explicit values instead */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  caret-color: auto;
  color: var(--one-ux-palette--brand-neutral-600);
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font: var(--one-ux-font--body-300);
  font-feature-settings: normal;
  font-kerning: auto;
  font-size-adjust: none;
  font-stretch: normal;
  font-synthesis: weight style;
  font-variant-position: normal;
  hanging-punctuation: none;
  hyphens: manual;
  image-orientation: 0deg;
  image-rendering: auto;
  image-resolution: normal;
  letter-spacing: var(--one-ux-letter-spacing--body-300);
  line-break: auto;
  list-style: disc outside none;
  object-position: 50% 50%;
  orphans: 2;
  overflow-wrap: normal;
  quotes: '"' '"' "'" "'";
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-combine-upright: none;
  text-indent: 0;
  text-justify: auto;
  text-orientation: mixed;
  text-shadow: none;
  text-transform: none;
  text-underline-position: auto;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  writing-mode: horizontal-tb;
}

/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIuaomQNQcsA88c7O9yZ4KMCoOg4Koz4y6qhA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/plusjakartasans/v2/LDIoaomQNQcsA88c7O9yZ4KMCoOg4Ko20yw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOiCnqEu92Fr1Mu51QrEzAdLw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TjASc6CsQ.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1Mu51xIIzI.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51S7ACc6CsQ.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOjCnqEu92Fr1Mu51TLBCc6CsQ.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgVxIIzI.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmYUtfBBc4.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x7DF4xlVMF-BfR8bXMIjhOm32WWg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotomono/v22/L0x5DF4xlVMF-BfR8bXMIjhLq38.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

